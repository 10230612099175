<template>

  <done-view>
    <template v-slot:days-selection="{ optimizedData, api_data }">
      <h3 class="is-size-6">
        <span class="empty-ph">{{strings.done && strings.done.severity_item_title['norain']}}</span>
      </h3>
      <price-checker :prices="getPrices(optimizedData, api_data)"/>

      <!-- PRICE SECTION -->
      <div class="row no-gutters">
        <div class="col">
          <div class="wet-note my-2">
            {{strings.done && strings.done.select_insurer_sub}}
          </div>

          <h3 class="is-size-6 text-right">
            <span class="empty-ph">
              {{strings.commonApp && strings.commonApp.price_including_taxes}}
            </span>
          </h3>

          <div class="row no-gutters">
            <div v-if="getSelectedPrice(api_data).percent !== false" class="is-size-7 col">
              {{ strings.done &&
                format(strings.done.alt_price_text, getSelectedPrice(api_data).percent) }}
            </div>

            <div class="col text-right font-weight-bold">
              <money-field v-if="getSelectedPrice(api_data).price_alt" :currency="api_data.currency"
                          :value="getSelectedPrice(api_data).price_alt" crossout dynamicCents />
              <br v-if="getSelectedPrice(api_data).price_alt">
              <money-field dynamicCents
                          :value="getSelectedPrice(api_data).price" :currency="api_data.currency"/>
            </div>
          </div>

        </div>
      </div>
    </template>
  </done-view>

</template>

<script>

import priceChecker from '@/components/done/PriceChecker.vue';
import done from '@/mixins/done';
import common from '@/mixins/common';
import doneView from './DoneView.vue';

export default {
  name: 'DoneBundle',
  components: {
    doneView,
    priceChecker,
    moneyField: () => import(/* webpackChunkName: "moneyField" */ '@/components/ui/moneyFieldUi.vue'),
  },
  mixins: [done, common],
  data() {
    return {};
  },
  methods: {
    getPrices(optimizedData, api_data) {
      const prices = [];

      optimizedData.forEach((item) => {
        if (item.f_in_risk_boundaries) {
          prices.push({
            rainyDays: api_data.contract_days - item.risk_days,
            pricePercent: Math.round(item.ratio_bundle_min_price_bundle_maximal_payout * 100, 2),
          });
        }
      });

      return prices;
    },
    getSelectedPrice(api_data) {
      return {
        price: api_data.prices ? api_data.prices[0].price : 0,
        price_alt: api_data.prices ? api_data.prices[0].alternative_price : false,
        percent: api_data.prices ? !!api_data.prices[0].alternative_price
          && Math.round((1 - (api_data.prices[0].price / api_data.prices[0].alternative_price))
            * 100) : 0,
      };
    },
  },
};
</script>

<style lang="scss">

</style>
