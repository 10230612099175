<template>
  <table-ui
    :head="tableHead"
    :body="tableBody"
    :radio="true"
    :selected.sync="selectedIndex"
  />
</template>

<script>
import tableUi from '@/components/ui/tableUi.vue';
import { mapState } from 'vuex';

export default {
  name: 'PriceChecker',
  components: {
    tableUi,
  },
  props: {
    prices: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      selectedIndex: 0,
    };
  },
  computed: {
    ...mapState(['strings']),
    stringsDone() {
      return this.strings.done || {};
    },
    selectedDays() {
      return this.tableBody[this.selectedIndex]?.rainyDays.text;
    },
    tableBody() {
      return this.prices.map((p) => ({
        rainyDays: {
          text: p.rainyDays,
        },
        pricePercent: {
          text: `${Number(p.pricePercent).toFixed(2)}%`,
        },
      }));
    },
    tableHead() {
      return {
        rainyDays: {
          text: this.stringsDone.bundle_table_rainy_days,
          align: 'center',
        },
        pricePercent: {
          text: this.stringsDone.bundle_table_price_percent,
          align: 'center',
        },
      };
    },
  },
};
</script>

<style lang="scss">

</style>
